import React, {Component} from "react";
import PropTypes from "prop-types";
import {Url} from "../../constants/global";

import TableUserDropdown from "../Dropdowns/TableUserDropdown";
import {toast} from "react-toastify";

class UserGroupTable extends Component {

    state = { totalGroups: 0, currentGroups: [],
        currentPage: null, totalPages: null,
        showModalPermissions: false, showModalEdit: false,
        currentItem: null, perms: []
    }

    getToken() {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token
    }

    componentDidMount() {
        this.refreshData()
    }

    refreshData = () => {
        fetch(Url + '/group', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }).then(res => res.json()).then((data) => {
            this.setState({totalGroups: data.len, currentGroups: data});
        })
        fetch(Url + '/access', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }).then(res => res.json()).then((data) => {
            this.setState({perms: data})
        });
    }

    saveGroup = () => {
        const { currentItem } = this.state;
        const data = Object.assign({}, currentItem);
        fetch(Url + '/group/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(()=>{
                toast.success('Group created !');
                this.setState({showModalEdit: false})
                this.refreshData()
            })
    }

    removeGroup = (item) => {
        fetch(Url + '/group/'+item.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            },
            body: ''
        }).then(()=>{
            toast.success('Group deleted !');
            this.refreshData()
        })
    }

    savePermissions = () => {

    }

    handleAddPerms = () => {

    }

    handleGroupnameChange = (event) => {
        const { currentItem } = this.state;
        currentItem.group_name = event.target.value;
        this.setState({currentItem: currentItem});
    }

    permissionGroup = item => {
        this.setState({showModalPermissions: true, currentItem: item})
    }

    handleAddGroup = () => {
        let currentItem = {
            group_name: ''
        }
        this.setState({showModalEdit: true, currentItem, action: 'add'});
    }

    render() {
        const { totalGroups, currentGroups, showModalPermissions, showModalEdit, currentItem } = this.state;

        if (totalGroups === 0) return null;
        return (
            <>
                <div
                    className={
                        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                        (this.props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
                    }
                >
                    <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3
                                    className={
                                        "font-semibold text-lg " +
                                        (this.props.color === "light" ? "text-slate-700" : "text-white")
                                    }
                                >
                                    Group
                                    <button
                                        className="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={this.handleAddGroup}
                                    >
                                        <i className="fas fa-plus"/>
                                    </button>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="block w-full overflow-x-auto">
                        {/* Projects table */}
                        <table className="items-center w-full bg-transparent border-collapse table-fixed">
                            <thead>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (this.props.color === "light"
                                            ? "bg-slate-50 text-slate-500 border-slate-100"
                                            : "bg-sky-800 text-sky-300 border-sky-700")
                                    }
                                >
                                    Name
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (this.props.color === "light"
                                            ? "bg-slate-50 text-slate-500 border-slate-100"
                                            : "bg-sky-800 text-sky-300 border-sky-700")
                                    }
                                >
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentGroups.map((item) => {
                                return (
                                    <tr className="whitespace-normal" key={item.id}>
                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                            {" "}
                                            <span
                                                className={
                                                    "ml-3 font-bold " +
                                                    +(this.props.color === "light" ? "text-slate-600" : "text-white")
                                                }
                                            >{item.group}</span>
                                        </th>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                            <TableUserDropdown onPermissionClick={this.permissionGroup} onRemoveClick={this.removeGroup} item={item}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                {showModalPermissions ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Edit Permissions
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => this.setState({showModalPermissions: false})}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="mb-3 pt-0">
                                                Permissions Group
                                            </div>
                                            <div className="mb-3 pt-0">
                                                {this.state.perms.map((item) => {
                                                    if (item.user_id === currentItem.id )
                                                        return (
                                                            <div key={"perms-"+item.id}>
                                                                <input type="text" value={item.permission} onChange={this.handleAddPerms}/>
                                                            </div>
                                                        )
                                                    else
                                                        return null
                                                })}
                                            </div>
                                        </form>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => this.setState({showModalPermissions: false})}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={this.savePermissions}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
                    {showModalEdit ? (
                        <>
                            <div
                                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                            >
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                    {/*content*/}
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        {/*header*/}
                                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                            <h3 className="text-3xl font-semibold">
                                                Add Group
                                            </h3>
                                            <button
                                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                onClick={() => this.setState({showModalEdit: false})}
                                            ><span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                            </button>
                                        </div>
                                        {/*body*/}
                                        <div className="relative p-6 flex-auto">
                                            <form>
                                                <div className="mb-3 pt-0">
                                                    <label>
                                                        Name
                                                        <input type="text" placeholder="Group Name" value={currentItem.group_name} onChange={this.handleGroupnameChange}
                                                               className="px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
                                                    </label>
                                                </div>
                                            </form>
                                        </div>
                                        {/*footer*/}
                                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                            <button
                                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => this.setState({showModalEdit: false})}
                                            >
                                                Close
                                            </button>
                                            <button
                                                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={this.saveGroup}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                </div>
            </>
        );
    }
}

UserGroupTable.defaultProps = {
    color: "light",
};

UserGroupTable.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};

export default UserGroupTable;