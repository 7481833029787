import React, {Component} from "react";
import PropTypes from "prop-types";
import {Url} from "../../constants/global";

// components

import Pagination from "../Pagination/Pagination";
import TableWebhookDropdown from "../Dropdowns/TableWebhookDropdown";
import {toast} from "react-toastify";

class WebhooksTable extends Component {

  state = { totalWebhooks: 0, currentWebhooks: [], currentPage: null, totalPages: null, showModal: false, currentItem: null, action: 'edit' }

  events = [
      'ORDER_CREATED', 'ANN_CREATED', 'REPORT_CREATED',
      'CANCEL_CREATED', 'ACQ_FIAB_CREATED', 'AR_CREATED', 'CR_CREATED', 'CRI_CREATE',
      'REAPTG_SENT', 'PREPTG_SENT', 'ANNPTG_SENT', 'MODPTG_SENT',
      'ORDER_FILE_SENT', 'AR_FILE_SENT', 'CRI_FILE_SENT', 'CR_FILE_SENT', 'AROK_RECEIVED',
      'ARKO_RECEIVED', 'CROK_RECEIVED', 'CRKO_RECEIVED', 'ETUDE_OK_RECEIVED',
      'ACQ_FIAB_RECEIVED', 'FILE_REJECTED', 'PORTING_OUT_ORDER_RECEIVED'
  ]

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }

  componentDidMount() {
    this.refreshData()
  }

  refreshData = () => {
      fetch(Url + '/webhook', {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.getToken()
          }
      }).then(res => res.json()).then((data) => {
        this.setState({totalWebhooks: data.count, currentWebhooks: data.results});
      })
  }

  onPageChanged = data => {
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    fetch(Url + '/webhook?offset='+offset+'&limit='+pageLimit, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    }).then(res => res.json()).then((results) => {
      this.setState({ currentPage, totalWebhooks: results.count, currentWebhooks: results.results, totalPages });
    })
  }

  editClick = item => {
      this.setState({showModal: true, currentItem: item, action: 'edit'})
  }

    duplicateClick = item => {
      const data = Object.assign({}, item);
      delete data.id;
      data.scope = '';
        this.setState({showModal: true, currentItem: data, action: 'add'})
    }

    removeClick = item => {
        fetch(Url + '/webhook/'+item.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            },
            body: ''
        }).then(()=>{
            toast.success('Webhook deleted !');
            this.refreshData();
        })
    }

  saveWebhook = () => {
      const { action, currentItem } = this.state;
      const data = Object.assign({}, currentItem);
      delete data.id;
      if(action === 'edit')
          fetch(Url + '/webhook/'+currentItem.id, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + this.getToken()
              },
              body: JSON.stringify(data)
          }).then(res => res.json())
          .then(()=>{
              toast.success('Webhook updated !');
              this.setState({showModal: false})
          })
      else
          fetch(Url + '/webhook/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + this.getToken()
              },
              body: JSON.stringify(data)
          }).then(res => res.json())
          .then(()=>{
              toast.success('Webhook created !');
              this.setState({showModal: false});
              this.refreshData()
          })
  }

  handleCheckEvent = (event) => {
      const { currentItem } = this.state;
      if(event.target.checked)
        currentItem.events.push(event.target.value);
      else {
          currentItem.events = currentItem.events.filter((value)=>{
              return value !== event.target.value;
          })
      }
      this.setState({currentItem: currentItem});
  }

    handleScopeChange = (event) => {
        const { currentItem } = this.state;
        currentItem.scope = event.target.value;
        this.setState({currentItem: currentItem});
    }
    handleHeaderChange = (event) => {
        const { currentItem } = this.state;
        currentItem.headers = JSON.parse(event.target.value);
        this.setState({currentItem: currentItem});
    }
    handleUrlChange= (event) => {
        const { currentItem } = this.state;
        currentItem.url = event.target.value;
        this.setState({currentItem: currentItem});
    }

    handleAddWebhook = () => {
        let currentItem = {
            scope: '',
            url: '',
            headers: '',
            events: []
        }
        this.setState({showModal: true, currentItem, action: 'add'});
    }

  render() {
    const { totalWebhooks, currentWebhooks, showModal, currentItem } = this.state;

    if (totalWebhooks === 0) return null;
    return (
        <>
          <div
              className={
                  "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                  (this.props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
              }
          >
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3
                      className={
                          "font-semibold text-lg " +
                          (this.props.color === "light" ? "text-slate-700" : "text-white")
                      }
                  >
                    Webhook
                      <button
                          className="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={this.handleAddWebhook}
                      >
                          <i className="fas fa-plus"/>
                    </button>
                  </h3>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="items-center w-full bg-transparent border-collapse table-fixed">
                <thead>
                <tr>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    Scope
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    URL
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    Events
                  </th>
                    <th
                        className={
                            "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                            (this.props.color === "light"
                                ? "bg-slate-50 text-slate-500 border-slate-100"
                                : "bg-sky-800 text-sky-300 border-sky-700")
                        }
                    >
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {currentWebhooks.map((item) => {
                  return (
                      <tr className="whitespace-normal" key={item.id}>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {" "}
                          <span
                              className={
                                  "ml-3 font-bold " +
                                  +(this.props.color === "light" ? "text-slate-600" : "text-white")
                              }
                          >
                            {item.scope}
                          </span>
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                          {item.url}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {item.events.join('\n')}
                        </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                              <TableWebhookDropdown onEditClick={this.editClick} onRemoveClick={this.removeClick} onDuplicateClick={this.duplicateClick} item={item}/>
                          </td>
                      </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-row py-4 align-items-center">
            <Pagination totalRecords={totalWebhooks} pageLimit={25} pageNeighbours={1} onPageChanged={this.onPageChanged} />
          </div>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {this.state.action === 'add' ? 'Add' : 'Edit'} Webhook
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-blackfloat-right text-3xl leading-none font-semibold"
                                        onClick={() => this.setState({showModal: false})}
                                    >
                                    <span className="bg-transparent text-black h-6 w-6 text-2xl block">
                                      ×
                                    </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <form>
                                        <div className="mb-3 pt-0">
                                            <label>
                                                Scope
                                                <input type="text" placeholder="Scope" value={currentItem.scope} onChange={this.handleScopeChange}
                                                className="px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
                                            </label>
                                        </div>
                                        <div className="mb-3 pt-0">
                                            <label>
                                                Url
                                                <input type="text" placeholder="URL" value={currentItem.url} onChange={this.handleUrlChange}
                                                          className="px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
                                            </label>
                                        </div>

                                        <div className="mb-3 pt-0">
                                            <label>
                                                Headers
                                                <textarea className="px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full" onChange={this.handleHeaderChange}>{JSON.stringify(currentItem.headers)}</textarea>
                                            </label>
                                        </div>

                                        <div className="mb-3 pt-0">
                                            Events
                                        </div>
                                        <div className="mb-3 pt-0 columns-3">
                                            {this.events.map((item) => {
                                                return (
                                                    <div key={"events-"+item}>
                                                        <input type="checkbox" value={item} defaultChecked={ currentItem.events.indexOf(item) >= 0} onChange={this.handleCheckEvent}/> {item}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </form>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => this.setState({showModal: false})}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={this.saveWebhook}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
  }
}

WebhooksTable.defaultProps = {
  color: "light",
};

WebhooksTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

export default WebhooksTable;