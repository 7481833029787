import React from "react";

export default function FooterAdmin() {
  return (
    <>
      <footer className="block py-4 fixed bottom-10">
        <div className="container mx-auto px-4">
          <hr className="mb-4 border-b-1 border-slate-200" />
        </div>
      </footer>
    </>
  );
}
