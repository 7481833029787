import React from "react";
import { createPopper } from "@popperjs/core";

const TableUserDropdown = (props) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <button
        className="text-slate-500 py-1 px-3"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"/>
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
          {props.onEditClick && (<button
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 hover:bg-slate-100"
          }
          onClick={(e) => {
              e.preventDefault();
              closeDropdownPopover();
              props.onEditClick(props.item);
          }}
        >
          Edit
        </button>)}
          {props.onDisableClick && (
          <button
              className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 hover:bg-slate-100"
              }
              onClick={(e) => {
                  e.preventDefault();
                  closeDropdownPopover();
                  props.onDisableClick(props.item);
              }}
          >
              Disable
          </button>)}
          {props.onPermissionClick && (<button
              className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 hover:bg-slate-100"
              }
              onClick={(e) => {
                  e.preventDefault();
                  closeDropdownPopover();
                  props.onPermissionClick(props.item);
              }}
          >
              Permissions
          </button>)}
          {props.onRemoveClick && (<button
              className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 hover:bg-slate-100"
              }
              onClick={(e) => {
                  e.preventDefault();
                  closeDropdownPopover();
                  props.onRemoveClick(props.item);
              }}
          >
              Remove
          </button>)}
      </div>
    </>
  );
};

export default TableUserDropdown;
