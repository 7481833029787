import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import Login from './views/auth/Login';
import Admin from "./layouts/Admin";
import useToken from "./components/App/useToken";
import {ToastContainer} from "react-toastify";

function App() {
    const { token, setToken } = useToken();

    if(!token) {
        return <Login setToken={setToken} />
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/admin" component={Admin} />
                <Route path="*" render={() => <Navigate to="/admin" />} />
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;