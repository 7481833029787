import React, {Component} from "react";
import PropTypes from "prop-types";
import {Url} from "../../constants/global";

import Pagination from "../Pagination/Pagination";
import TableUserDropdown from "../Dropdowns/TableUserDropdown";
import {toast} from "react-toastify";

class UserTable extends Component {

    state = { groups: [], totalUsers: 0, currentUsers: [],
        currentPage: null, totalPages: null,
        showModalPermissions: false, showModalEdit: false,
        currentItem: null, perms: []
    }

    getToken() {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token
    }

    componentDidMount() {
        fetch(Url + '/user', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }).then(res => res.json()).then((data) => {
            this.setState({totalUsers: data.count, currentUsers: data.results});
        })
        fetch(Url + '/group', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }).then(res => res.json()).then((data) => {
            this.setState({groups: data})
        })
        fetch(Url + '/access', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }).then(res => res.json()).then((data) => {
            this.setState({perms: data})
        });
    }

    onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        fetch(Url + '/user?offset='+offset+'&limit='+pageLimit, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            }
        }).then(res => res.json()).then((results) => {
            this.setState({ currentPage, totalUsers: results.count, currentUsers: results.results, totalPages });
        })
    }

    editClick = item => {
        this.setState({showModalEdit: true, currentItem: item})
    }

    saveUser = () => {
        const { currentItem } = this.state;
        const data = Object.assign({}, currentItem);
        delete data.id;
        fetch(Url + '/user/'+currentItem.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(()=>{
                toast.success('User updated !');
                this.setState({showModalEdit: false})
            })
    }

    savePermissions = () => {

    }

    handleAddPerms = () => {

    }

    handleUsernameChange = (event) => {
        const { currentItem } = this.state;
        currentItem.username = event.target.value;
        this.setState({currentItem: currentItem});
    }

    handleGroupChange = (event) => {
        const { currentItem } = this.state;
        currentItem.group_id = parseInt(event.target.value);
        this.setState({currentItem: currentItem});
    }

    disableUser = (item) => {
        fetch(Url + '/user/'+item.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getToken()
            },
            body: JSON.stringify({disabled: !item.disabled, username: item.username})
        }).then(res => res.json())
            .then(()=>{
                item.disabled = !item.disabled;
                toast.success('User '+(!item.disabled ? 'disabled' : 'enabled')+ '!');
            })
    }

    permissionUser = item => {
        this.setState({showModalPermissions: true, currentItem: item})
    }

    render() {
        const { totalUsers, currentUsers, showModalPermissions, showModalEdit, currentItem, groups } = this.state;

        if (totalUsers === 0) return null;
        return (
            <>
                <div
                    className={
                        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                        (this.props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
                    }
                >
                    <div className="rounded-t mb-0 px-4 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3
                                    className={
                                        "font-semibold text-lg " +
                                        (this.props.color === "light" ? "text-slate-700" : "text-white")
                                    }
                                >
                                    User
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="block w-full overflow-x-auto">
                        {/* Projects table */}
                        <table className="items-center w-full bg-transparent border-collapse table-fixed">
                            <thead>
                            <tr>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (this.props.color === "light"
                                            ? "bg-slate-50 text-slate-500 border-slate-100"
                                            : "bg-sky-800 text-sky-300 border-sky-700")
                                    }
                                >
                                    Username
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (this.props.color === "light"
                                            ? "bg-slate-50 text-slate-500 border-slate-100"
                                            : "bg-sky-800 text-sky-300 border-sky-700")
                                    }
                                >
                                    Group
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (this.props.color === "light"
                                            ? "bg-slate-50 text-slate-500 border-slate-100"
                                            : "bg-sky-800 text-sky-300 border-sky-700")
                                    }
                                >
                                    Enabled
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                        (this.props.color === "light"
                                            ? "bg-slate-50 text-slate-500 border-slate-100"
                                            : "bg-sky-800 text-sky-300 border-sky-700")
                                    }
                                >
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentUsers.map((item) => {
                                return (
                                    <tr className="whitespace-normal" key={item.id}>
                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                            {" "}
                                            <span
                                                className={
                                                    "ml-3 font-bold " +
                                                    +(this.props.color === "light" ? "text-slate-600" : "text-white")
                                                }
                                            >{item.username}</span>
                                        </th>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                            {groups.map((group) => (item.group_id === group.id ? group.group : ''))}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle items-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {!item.disabled ?
                                                <i className="fa fa-check-circle text-green-600">&nbsp;</i>
                                                : <i className="fa fa-times-circle text-red-600">&nbsp;</i>
                                            }
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                            <TableUserDropdown onEditClick={this.editClick} onDisableClick={this.disableUser} onPermissionClick={this.permissionUser} item={item}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex flex-row py-4 align-items-center">
                    <Pagination totalRecords={totalUsers} pageLimit={25} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                </div>
                {showModalPermissions ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Edit Permissions
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => this.setState({showModalPermissions: false})}
                                        >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="mb-3 pt-0">
                                                Permissions User
                                            </div>
                                            <div className="mb-3 pt-0">
                                                {this.state.perms.map((item) => {
                                                    if (item.user_id === currentItem.id )
                                                        return (
                                                            <div key={"perms-"+item.id}>
                                                                <input type="text" value={item.permission} onChange={this.handleAddPerms}/>
                                                            </div>
                                                        )
                                                    else
                                                        return null
                                                })}
                                            </div>
                                        </form>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => this.setState({showModalPermissions: false})}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={this.savePermissions}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
                {showModalEdit ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"

                        >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Edit User
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => this.setState({showModalEdit: false})}
                                        >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="mb-3 pt-0">
                                                <label>
                                                    Username
                                                    <input type="text" placeholder="Scope" value={currentItem.username} onChange={this.handleUsernameChange}
                                                           className="px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
                                                </label>
                                            </div>

                                            <div className="mb-3 pt-0">
                                                Group
                                            </div>
                                            <div className="mb-3 pt-0">
                                                <select name="group" value={currentItem.group_id} onChange={this.handleGroupChange}>
                                                    <option>-------</option>
                                                    {this.state.groups.map((group)=>{
                                                        return <option key={group.id} value={group.id}>{group.group}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => this.setState({showModalEdit: false})}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={this.saveUser}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>
        );
    }
}

UserTable.defaultProps = {
    color: "light",
};

UserTable.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};

export default UserTable;