import React, {Component} from "react";
import PropTypes from "prop-types";
import {Url} from "../../constants/global";
import Pagination from "../Pagination/Pagination";

class AuditTable extends Component {

  state = { totalAudits: 0, currentAudits: [], currentPage: null, totalPages: null }

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }

  componentDidMount() {
    fetch(Url + '/audit', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    })
        .then(res => res.json())
        .then(
            (data) => {
              this.setState({totalAudits: data.count, currentAudits: data.results});
            }
        )
  }

  onPageChanged = data => {
    const { currentPage, totalPages, pageLimit } = data;

    fetch(Url + '/audit?offset='+currentPage+'&limit='+pageLimit, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    }).then(res => res.json()).then((results) => {
              this.setState({ currentPage, totalAudits: results.count, currentAudits: results.results, totalPages });
    })
  }

  render() {
    const { totalAudits, currentAudits } = this.state;

    if (totalAudits === 0) return null;
    return (
        <>
          <div
              className={
                  "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                  (this.props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
              }
          >
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3
                      className={
                          "font-semibold text-lg " +
                          (this.props.color === "light" ? "text-slate-700" : "text-white")
                      }
                  >
                    Audit
                  </h3>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="items-center w-full bg-transparent border-collapse table-fixed">
                <thead>
                <tr>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    Type
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    Data
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-slate-50 text-slate-500 border-slate-100"
                              : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                  >
                    Created At
                  </th>
                </tr>
                </thead>
                <tbody>
                {currentAudits.map((item) => {
                  return (
                      <tr className="whitespace-normal">
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {" "}
                          <span
                              className={
                                  "ml-3 font-bold " +
                                  +(this.props.color === "light" ? "text-slate-600" : "text-white")
                              }
                          >
                            {item.type}
                          </span>
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 ">
                          {item.data}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {item.created_at}
                        </td>
                      </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-row py-4 align-items-center">
            <Pagination totalRecords={totalAudits} pageLimit={25} pageNeighbours={1} onPageChanged={this.onPageChanged} />
          </div>
        </>
    );
  }
}

AuditTable.defaultProps = {
  color: "light",
};

AuditTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

export default AuditTable;